import { template as template_f5e370b7834548008a3cfa961eb5ea5b } from "@ember/template-compiler";
const FKLabel = template_f5e370b7834548008a3cfa961eb5ea5b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
